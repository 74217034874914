import React from "react";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";

const Confirmation = () => (
  <PageLayout>
    <SEO title="Confirmation" />
    <h1 style={{ margin: 20, fontSize: 18 }}>Message Sent</h1>
    <p style={{ marginLeft: 20, marginBottom: 500 }}>Thank you!</p>
  </PageLayout>
);

export default Confirmation;
